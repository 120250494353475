@use '../variables/colors';


.paper-box {
    width: 200px;
    height: 100px;
    background-color: red;
    // .box-div {
    //     margin-right: 10px;
    // }
}

.rdt_TableCol {
    font-weight: 600;
    color: azure;
    font-size: 11px;
    background-color: #95a4b2;
    height: 45px;
}

.rdt_TableCell {
    height: 40px;
    font-size: 11px;
}

.img-notfound {
    margin-top: 70px;
    img {
        width: 400px;
    }
}

.ais-RefinementList-showMore {
    background-color: #43a047;
}

.ais-RefinementList-showMore :hover {
    background-color: #ddd;
}
.ais-ClearRefinements-button {
    background-color: #43a047;
}

.text-notfound {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 20px;
    }
}

.card-statistic {
	h4 {
		font-weight: 300;
		color: #000;
		font-family: 'Roboto', sans-serif;
		letter-spacing: 1px;
	}
    h3 {
        font-weight: 400;
		font-size: 25px;
		color: #43a047;
		padding-top: 5px;
    }
	img {
		width: 50px;
	}
	display: flex;
	justify-content: space-between;
}

.card-statistic-status {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    h3 {
        font-weight: 400;
    }
    h1 {
        font-size: 40px;
    }
}

.floating-button {
    // position: absolute;
    font-size: 14px;
    bottom: 0;
    left: 0;
    margin-top: 30px;
    margin-bottom: 10px;
}

.floating-drawer {
    position: absolute;
    bottom: 0;
    // margin-bottom: 25px;
    // margin-right: 40px;
}
.menu-pop-over {
    margin-top: 5px;
    .box-menu {
        min-width: 260px;
        list-style: none;
        padding: 20px;
        margin: 0;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                background-color: #1b4559;
                border-radius: 5px;
                margin-bottom: 7px;
                margin-top: 7px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
                padding: 5px 15px;
            }
        }
    }
}

.menu-cart-button {
    margin-top: 10px;
    button {
        border: none;
        background-color: transparent;
    }
}

.css-mlqt2n-MuiTableRow-root {
    height: 30px !important;
}
.box-menu-cart {
    margin-left: 40px;
    margin-right: 40px; 

    .content-cart {
        height: 58vh;
        overflow-y: scroll;
    }
    .content-cart-empty {
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img {
            width: 200px;
            height: 200px;
        }
    }
}

.total-cart {
    padding: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        color: #1b4559;
        font-weight: 700;
    }
    
    .right-button {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        h6 {
            text-align: centers;
            cursor: pointer;
            font-weight: bold;
            font-size: 17px;
            color: #1b4559;
            margin-right: 20px;
        }
    }
}


// card info 
.cart-menu {
    background-color: colors.$primaryColor;
    cursor: pointer;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
        color: #fff;
    }
    .cart-text {
        display: flex;
        align-items: center;
    }
}

.vendor-title {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    h4{
        padding-top: 5px;
        margin: 0;
    }
}

.sub-total-cart {
    display: flex;
    justify-content: space-between;
    h4 {
        font-weight: 600;
    }
}

.loading-button {
    display: flex;
    align-items: center;
    p {
        margin-right: 5px;
    }
}

.search-bar {
    background-color: #fff;
}

.sc-iwyWTf {
    padding-top: 25px;
    padding-bottom: 25px;
}   



.list-pr {
    width: 100%;
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    li{
        padding: 8px 0px;
        border: 2px solid colors.$primaryColor;
        color: #000;
        text-align: center;
        margin-left: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 0px;
        border-radius: 4px;
        cursor: pointer;
    }
  }
  

  .list-item-tooltip {
      ul {
          list-style: none;
          padding-right: 5px;
          padding-left: 5px;
          color: #fff;
          .item-list-all {
              display: flex;
              flex-direction: row;
              span {
                  padding-right: 5px;
              }
          }
      }

  }


  .box-payment-choose {
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 10px;
      margin-top: 10px;
      text-align: center;
      a {
          color: #fff;
      }
  }

  .box-payment-method {
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    a {
        color: #000;
    }
}

.table-size-text {
    font-size: 10px;
}


// react-phone-input-code

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: inherit;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
    height: 50px;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
    height: 40px;
    border: 1px solid #ccc;
    font-size: 15px;
    padding-left: 10px;
    margin-left: 5px;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
    margin-right: 7px;
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 12px;
	height: 12px;
    color: #000;
	margin-left: 2px;
	border-style: solid;
	border-color: #000;
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}