.paper-chatFloating{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 500px;
    min-width:550px;
    display:flex; 
    flex-direction:row;
    animation-name: transitionUp;
    animation-duration: 0.5s;
}

@keyframes transitionUp {
    from {
        position:fixed;
        right: 40px;
        min-height:0px; 
        bottom: 0;
        opacity: -10;
        z-index:0; 
    }

    to{
        visibility: visible;
        position:fixed;
        height: 400px;
        width: 500px; 
        bottom: 10px;
        z-index:2000; 
        right:40px;
    }
}

.float-close{
    visibility: hidden;
    animation-name: hiddenAnimation;
    animation-duration: 0.8s;
    position: fixed;
    right: 40px;
    width: 500px;
}

@keyframes hiddenAnimation {
    from{
        position:fixed;
        height: 100px;
        min-height: 400px;
        width: 550px;
        right: 40px;
        bottom: 10px;
        z-index:2000; 
        right:40px;
    }

    to{
        visibility: visible;
        position:fixed;
        height: 100px;
        width: 550px;
        right: 40px;
        min-height:0px; 
        bottom: 0;
        opacity: -10;
        z-index:0; 
    }
    
}

.no-clicked{
    visibility: hidden;
}

.float-halfWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 180px;
    min-width:100px;
    display:flex; 
    flex-direction:row;
    animation-name: halfWidth;
    animation-duration: 0.6s;
}

@keyframes halfWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width : 500px
    }
    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width : 180px
    }
}

.half-to-fullWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 500px;
    min-width:550px;
    display:flex; 
    flex-direction:row;
    animation-name: toFullWidth;
    animation-duration: 0.8s;
}

@keyframes toFullWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        height: 400px;
        min-height:400px;
        width: 180px; 
        min-width: 180px;
        height: 0;
        width: 180px;
        z-index:2000; 
    }

    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 500px;
        min-width:550px;
    }
}

.float-close-halfwidth{
    visibility: hidden;
    animation-name: closeHalfWidth;
    animation-duration: 0.8s;
}

@keyframes closeHalfWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 180px;
        min-width:100px;
        display:flex; 
    }

    to{
        visibility: visible;
        position:fixed;
        bottom:10px;
        right:40px;
        min-height:0;
        width: 180px; 
        min-width: 180px;
        height: 0;
        width: 180px;
        opacity: -10;
        z-index:0; 
    }
}

.float-fullWidth{
    position:fixed;
    bottom:10px;
    right:40px;
    z-index:2000; 
    min-height:400px; 
    height: 400px;
    width: 500px;
    min-width:550px;
    display:flex; 
    flex-direction:row;
    animation-name: fullWidth;
    animation-duration: 0.6s;
}

@keyframes fullWidth {
    from{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 100px;
    }
    to{
        position:fixed;
        bottom:10px;
        right:40px;
        z-index:2000; 
        min-height:400px; 
        height: 400px;
        width: 500px;
    }
}

.buttonChatTransition{
    animation-name: buttonChat;
    animation-duration: 1s;
}

@keyframes buttonChat {
    from{
        opacity: 1;
    }

    to{
        opacity: -100;
    }
}


