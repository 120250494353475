@use '../variables/colors';

.logo-mpi {
    margin-top: 5px;
    img {
        width: 100px;
    }
    flex-grow: 1;
    background-color: colors.$primaryColor;
}

.logo-mpi-main {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    img {
        width: 100px;
    }
}

.logo-mpi-p {
    margin-right: 20px;
    margin-top: 10px;
    margin-left: 0px !important;
    img {
        width: 30px;
    }
    // border-right: #000 solid 1px;
}

.right-navbar {
    display: flex;
    cursor: pointer;
    color: #fff;
}

.text-navbar {
    a {
        color: #e8f0f5;
    }
}

.popper-style {
    margin-top: 20px;
}

.sidebar-navbar {
    font-size: 13px;
    ul {
        padding-left: 17px;
        list-style: none;
        margin: 0;
        background-color: #fff;
        display: flex;
        li {
            span {
                color: #000;
            }
            padding: 6px
        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                color: colors.$primaryColor;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
    }
}
.active-link {
    ul {
        border-right: 5px solid colors.$primaryColor;
        li {
            span {
                color: colors.$primaryColor;
                font-weight: 600;
            }
        }
        .navbar-list {
            display: flex;
            align-items: center;
            .icon {
                color: #fff;
                margin-right: 10px;
                margin-top: 5px;
            }
        }
    }
    
}
    

.sidebar-navbar-dropdown {
    padding-top: 15px;
    font-size: 13px;
    .menu-dropdown {
        padding-left: 25px;
        padding-right: 27px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .menu-list-dropdown {
        display: none;
    }
    .menu-list-dropdown-active {
        display: block;
        .navbar-list-child {
            padding-left: 12px;  
            display: flex;
            align-items: center;
            font-size: 13px;
            .icon {
                color: #fff;
                margin-right: 10px;
                margin-top: 5px;;
            }         
        }
    }
}


    