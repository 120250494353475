@use '../variables/colors';

.product-list-algolia {
    margin-bottom: 10px;
    margin-top: 10px;
    p {
        font-size: 18px;
    }
    h6 {
        padding-top: 10px;
        color : colors.$primaryColor;
        font-size: 15px;
    }
    h5 {
        padding-top: 10px;
        color : #e80e23;
        font-size: 15px;
    }
    span {
        color: #1b4559;
        font-size: 15px;
    }
}

.item-onselect {
    h1 {
        font-size: 21px;
    }
    h5 {
        padding-top: 5x;
        padding-bottom: 5px;
        font-size: 20px;
    }
    h4 {
        padding-top: 5x;
        padding-bottom: 5px;
        font-size: 17px;
        color: colors.$primaryColor;
    }
    span {
        color: #1b4559;
        font-size: 17px;
    }
    p {
        color: #e80e23;
    }
    
}

.price-item-select {
    h1 {
        padding-top: 5x;
        padding-bottom: 5px;
        font-size: 23px;
        color: #000;
    }
}

.aa-InputWrapper {
    z-index: 140  !important
}

.aa-Input {
    z-index: 140  !important
}